import { makeQueryStringDync, setHeader } from "../../helper";
  
  const actions = {
    mapEpgXmlData: (context, payload) => {
        if (payload?.orderby) {
            payload.orderby = encodeURI(JSON.stringify(payload?.orderby));
        } 
        let dataParams = makeQueryStringDync(payload);
        // let endPoint = payload.start + "&stop=" + payload.stop;
        // if(payload.page){
        //     endPoint = endPoint + "&page=" + payload.page;
        // }
        // if(payload.pagesize) {
        //     endPoint = endPoint + "&pagesize=" + payload.pagesize;
        // }
        let fetchUrl = `${context.getters.vCmsBaseUrl}subscriber/v2/epg${dataParams}`;
        // if(payload.idepgchannel) {
        //     fetchUrl+="&idepgchannel="+ payload.idepgchannel;
        // }
        return new Promise((resolve, reject) => {
            fetch(fetchUrl,
            {
                method: "GET",
                headers: setHeader(),
            }
            ).then((response) => {
                return response.text();
            }).then((data) => {
                resolve({ data });
            }).catch((error) => {console.log("--error--"), reject(error)});
        })
    }
  };

  export default{ actions }