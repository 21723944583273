import firebase from "firebase/app";
import "firebase/analytics";

const googleAnalytics = {
  data() {
    return {
      googleAnalytics: null,
    };
  },
  created() {
    this.googleAnalytics = firebase.analytics();
  },
  methods: {
    actAttachedUTMData(data) {
      let utm_details_global = localStorage.getItem("utm_details_global");
      utm_details_global = utm_details_global ? JSON.parse(utm_details_global) : {};
      return { ...data, ...utm_details_global };
    },
    loginEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "LOGIN";

      console.warn("THIS IS ANALYTICS EVENT", this.googleAnalytics);

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("login GA event occured", analyticsData);
      this.googleAnalytics.logEvent("login", analyticsData);
    },
    logoutEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "LOGOUT";
      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("logout GA event occured", analyticsData);
      this.googleAnalytics.logEvent("logout", analyticsData);
    },
    SignupEvent(data) {
      if (!this.googleAnalytics) return;
      let analyticsData = data;

      // analyticsData.EventCategory = "SIGNUP";
      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("signup GA event occured", analyticsData);
      this.googleAnalytics.logEvent("sign_up", analyticsData);
    },
    watchedEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "WATCH";
      console.log("watch GA event occured", analyticsData);
      analyticsData = this.actAttachedUTMData(analyticsData);

      this.googleAnalytics.logEvent("watched", analyticsData);
    },
    sharedEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "SHARED";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("share GA event occured", analyticsData);
      this.googleAnalytics.logEvent("shared", analyticsData);
    },
    subscriptionStarted(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "PAYMENT_STARTED";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("subscription started GA event occured", data);
      this.googleAnalytics.logEvent("payment_started", data);
    },
    subscriptionDone(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "SUBSCRIPTION_DONE";
      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("subscription done GA event occured", data);
      this.googleAnalytics.logEvent("charged", data);
    },
    subscriptionFailed(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "SUBSCRIPTION_FAILED";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("subscription failed GA event occured", data);
      this.googleAnalytics.logEvent("payment_failed", data);
    },
    subscriptionCancelled(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "SUBSCRIPTION_CANCELLED";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("subscription cancelled GA event occured", analyticsData);
      this.googleAnalytics.logEvent("Subscription_Cancelled", analyticsData);
    },
    searchEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "SEARCH";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("search GA event occured", analyticsData);
      this.googleAnalytics.logEvent("searched", analyticsData);
    },

    pageViewEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "PAGE_VIEW";

      analyticsData = this.actAttachedUTMData(analyticsData);

      this.googleAnalytics.logEvent("page_view", analyticsData);
    },
    createProfileEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "CREATE_PROFILE";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("create profile GA event occured", analyticsData);
      this.googleAnalytics.logEvent("create_profile", analyticsData);
    },
    userActionEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "ACTION";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("user action GA event occured", analyticsData);
      this.googleAnalytics.logEvent("action", analyticsData);
    },
    switchProfileEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "SWITCH_PROFILE";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("switch profile GA event", analyticsData);
      this.googleAnalytics.logEvent("switch_profile", analyticsData);
    },
    deleteProfileEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "DELETE_PROFILE";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("delete profile GA event occured", analyticsData);
      this.googleAnalytics.logEvent("delete_profile", analyticsData);
    },
    ratedEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "RATED";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("rated event occured", analyticsData);
      this.googleAnalytics.logEvent("rated", analyticsData);
    },
    referralEvent(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;
      // analyticsData.EventCategory = "REFERRAL";

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("referral event occured", analyticsData);
      this.googleAnalytics.logEvent("referral", analyticsData);
    },

    //Telco Events.
    telco_error_event(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco landing error event occured", analyticsData);

      this.googleAnalytics.logEvent("telco_landing_error", analyticsData);
    },

    telco_landing_event(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco landing event occured", analyticsData);

      this.googleAnalytics.logEvent("telco_landing_page", analyticsData);
    },

    telco_trial_initiated(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco trial event occured", analyticsData);

      this.googleAnalytics.logEvent("telco_trial_initiated", analyticsData);
    },
     
    telco_data_log(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco trial event occured", analyticsData);

      this.googleAnalytics.logEvent("telco_data_log", analyticsData);
    },

    telco_trial_success(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco trial success event occured", analyticsData);

      this.googleAnalytics.logEvent("telco_trial_success", analyticsData);
    },

    telco_trial_failed(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco trial failure event occured", analyticsData);

      this.googleAnalytics.logEvent("telco_trial_failed", analyticsData);
    },

    telco_trial_cancelled(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco trial failure event occured", analyticsData);

      this.googleAnalytics.logEvent("telco_trial_cancelled", analyticsData);
    },

    telco_subscribe_now(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco subscribe now event");

      this.googleAnalytics.logEvent("subscribe_now_intent", analyticsData);
    },

    telco_landing_page_tracker(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco_landing_page_tracker EVENT");

      this.googleAnalytics.logEvent("telco_landing_page_tracker", analyticsData);
    },

    telco_page_submission_tracker(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("telco_page_submission_tracker EVENT");

      this.googleAnalytics.logEvent("telco_page_submission_tracker", analyticsData);
    },

    // Analytics - content_id
    contentWatched(data) {
      if (!this.googleAnalytics) return;

      let analyticsData = data;

      analyticsData = this.actAttachedUTMData(analyticsData);

      console.log("watched EVENT", data);

      // this.googleAnalytics.logEvent("watched", data);
    },

    // Set user properties
    setUserPropertiesFirebase(data) {
      this.googleAnalytics.setUserProperties(data);
    } 
  },
};

export default googleAnalytics;
