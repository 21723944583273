import Vue from "vue";
import vueRouter from "vue-router";
import { routes } from "./routes";

Vue.use(vueRouter);

export const router = new vueRouter({
  routes,
  mode: "history"
});
