import jwt_decode from "jwt-decode";
import { store } from "@/store/store";
import { eventBus } from "@/eventBus";



export const actSaveExpireTime = (sec) => {

  var now = new Date();
  now.setSeconds(now.getSeconds() + (sec - 60));
  now = new Date(now); // Date object
  localStorage.setItem("saveExpiredatetime", now)
}


export const getFreshTokenUpdate = () => {
    
    let myTimer = function () {
       let refreshTimer = localStorage.getItem('refresh-token-expire');
       localStorage.setItem('refresh-token-expire', parseInt(refreshTimer) - 15)
       let getExpireTime = localStorage.getItem('saveExpiredatetime');
        let currentTime = new Date();
       if (new Date(getExpireTime) < currentTime) {
         clearInterval(window.myIntervalreFreshToken);
       } else {
         return;
       }
       

       store.dispatch("actRefreshToken").then((tokenResponse) => {
         if(tokenResponse.refreshtoken) {
           localStorage.setItem("sessionToken", tokenResponse.success);
           localStorage.setItem("refreshtoken", tokenResponse.refreshtoken);
           try {
             localStorage.setItem("refresh-token-expire", jwt_decode(tokenResponse.success).expiresIn);
             actSaveExpireTime(jwt_decode(tokenResponse.success).expiresIn)

             if (window.myIntervalreFreshToken) {
              clearInterval(window.myIntervalreFreshToken);
            }
            window.myIntervalreFreshToken = setInterval(myTimer, 15000);

           } catch (e) {} 
         } else {
          if (localStorage.getItem("sessionToken")) {
            eventBus.$emit("subscriberLogout");
          }
         }
       }).catch(() => {
        if (localStorage.getItem("sessionToken")) {
          eventBus.$emit("subscriberLogout");
        }
       })
     }

     if (window.myIntervalreFreshToken) {
        clearInterval(window.myIntervalreFreshToken);
     }
    window.myIntervalreFreshToken = setInterval(myTimer, 15000);
     
  


}

