const cleverTap = {
  methods: {
    //working
    loginCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Name") === true) {
        data.Name = cleverData.Name;
      }
      if (cleverData.hasOwnProperty("Identity") === true) {
        data.Identity = cleverData.Identity;
      }
      if (cleverData.hasOwnProperty("Email") === true) {
        data.Email = cleverData.Email;
      }
      if (cleverData.hasOwnProperty("Phone") === true) {
        data.Phone = cleverData.Phone;
      }
      if (cleverData.hasOwnProperty("Gender") === true) {
        data.Gender = cleverData.Gender;
      }
      if (cleverData.hasOwnProperty("DOB") === true) {
        data.DOB = cleverData.DOB;
      }
      if (cleverData.hasOwnProperty("Photo") === true) {
        data.Photo = cleverData.Photo;
      }
      if (cleverData.hasOwnProperty("MSG-email") === true) {
        data["MSG-email"] = cleverData["MSG-email"];
      }
      if (cleverData.hasOwnProperty("MSG-push") === true) {
        data["MSG-push"] = cleverData["MSG-push"];
      }
      if (cleverData.hasOwnProperty("MSG-sms") === true) {
        data["MSG-sms"] = cleverData["MSG-sms"];
      }

      let login_data = {
        Site: data
      };

      console.log("console for login clever event", login_data);

      // clevertap.onUserLogin.push(login_data);
    },
    //working
    signUpCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Sign_Up_Mode") === true) {
        data.Sign_Up_Mode = cleverData.Sign_Up_Mode;
      }
      if (cleverData.hasOwnProperty("Status") === true) {
        data.Status = cleverData.Status;
      }
      if (cleverData.hasOwnProperty("Error_Reason") === true) {
        data.Error_Reason = cleverData.Error_Reason;
      }

      console.log("console for signup clever event", data);

      // clevertap.event.push("signup", data);
    },
    //working
    watchedCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Content_ID") === true) {
        data.Content_ID = cleverData.Content_ID;
      }
      if (cleverData.hasOwnProperty("Content_Title") === true) {
        data.Content_Title = cleverData.Content_Title;
      }
      if (cleverData.hasOwnProperty("Content_Type") === true) {
        data.Content_Type = cleverData.Content_Type;
      }
      if (cleverData.hasOwnProperty("Content_Genre") === true) {
        data.Content_Genre = cleverData.Content_Genre;
      }
      if (cleverData.hasOwnProperty("Content_Language") === true) {
        data.Content_Language = cleverData.Content_Language;
      }
      if (cleverData.hasOwnProperty("Watch_Time") === true) {
        data.Watch_Time = cleverData.Watch_Time;
      }
      if (cleverData.hasOwnProperty("Content_Duration") === true) {
        data.Content_Duration = cleverData.Content_Duration;
      }
      if (cleverData.hasOwnProperty("Content_Price") === true) {
        data.Content_Price = cleverData.Content_Price;
      }
      if (cleverData.hasOwnProperty("Playback_Type") === true) {
        data.Playback_Type = cleverData.Playback_Type;
      }
      if (cleverData.hasOwnProperty("Streamed_Upto_25perc") === true) {
        data.Streamed_Upto_25perc = cleverData.Streamed_Upto_25perc;
      }
      if (cleverData.hasOwnProperty("Streamed_Upto_50perc") === true) {
        data.Streamed_Upto_50perc = cleverData.Streamed_Upto_50perc;
      }
      if (cleverData.hasOwnProperty("Streamed_Upto_75perc") === true) {
        data.Streamed_Upto_75perc = cleverData.Streamed_Upto_75perc;
      }
      if (cleverData.hasOwnProperty("Network_Type") === true) {
        data.Network_Type = cleverData.Network_Type;
      }
      if (cleverData.hasOwnProperty("Finished_Watching") === true) {
        data.Finished_Watching = cleverData.Finished_Watching;
      }
      if (cleverData.hasOwnProperty("Start_Position") === true) {
        data.Start_Position = cleverData.Start_Position;
      }

      // clevertap.event.push("watched", data);
    },
    //working
    sharedCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Content_ID") === true) {
        data.Content_ID = cleverData.Content_ID;
      }
      if (cleverData.hasOwnProperty("Content_Title") === true) {
        data.Content_Title = cleverData.Content_Title;
      }
      if (cleverData.hasOwnProperty("Content_Type") === true) {
        data.Content_Type = cleverData.Content_Type;
      }
      if (cleverData.hasOwnProperty("Content_Genre") === true) {
        data.Content_Genre = cleverData.Content_Genre;
      }
      if (cleverData.hasOwnProperty("Content_Language") === true) {
        data.Content_Language = cleverData.Content_Language;
      }
      if (cleverData.hasOwnProperty("Content_Duration") === true) {
        data.Content_Duration = cleverData.Content_Duration;
      }
      if (cleverData.hasOwnProperty("Content_Price") === true) {
        data.Content_Price = cleverData.Content_Price;
      }
      if (cleverData.hasOwnProperty("Medium") === true) {
        data.Medium = cleverData.Medium;
      }

      console.log("shared event from clevertap", data);

      // clevertap.event.push("shared", data);
    },
    //working
    paymentStartedEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Payment_Mode") === true) {
        data.Payment_Mode = cleverData.Payment_Mode;
      }
      if (cleverData.hasOwnProperty("Plan_ID") === true) {
        data.Plan_ID = cleverData.Plan_ID;
      }
      if (cleverData.hasOwnProperty("Plan_Name") === true) {
        data.Plan_Name = cleverData.Plan_Name;
      }
      if (cleverData.hasOwnProperty("Payment_ID") === true) {
        data.Payment_ID = cleverData.Payment_ID;
      }

      console.log("payment started event from clevertap", data);

      // clevertap.event.push("Payment_Started", data);
    },
    //working
    chargedCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Payment_Mode") === true) {
        data.Payment_Mode = cleverData.Payment_Mode;
      }
      if (cleverData.hasOwnProperty("Subscription_Start_Date") === true) {
        data.Subscription_Start_Date = cleverData.Subscription_Start_Date;
      }
      if (cleverData.hasOwnProperty("Subscription_End_Date") === true) {
        data.Subscription_End_Date = cleverData.Subscription_End_Date;
      }
      if (cleverData.hasOwnProperty("Transaction_ID") === true) {
        data.Transaction_ID = cleverData.Transaction_ID;
      }
      if (cleverData.hasOwnProperty("Amount") === true) {
        data.Amount = cleverData.Amount;
      }
      if (cleverData.hasOwnProperty("Currency_Code") === true) {
        data.Currency_Code = cleverData.Currency_Code;
      }
      if (cleverData.hasOwnProperty("Payment_ID") === true) {
        data.Payment_ID = cleverData.Payment_ID;
      }
      if (cleverData.hasOwnProperty("Promo_Code") === true) {
        data.Promo_Code = cleverData.Promo_Code;
      }
      if (cleverData.hasOwnProperty("Discount_Amount") === true) {
        data.Discount_Amount = cleverData.Discount_Amount;
      }
      if (cleverData.hasOwnProperty("Free_Trial") === true) {
        data.Free_Trial = cleverData.Free_Trial;
      }
      if (cleverData.hasOwnProperty("Plan_ID") === true) {
        data.Plan_ID = cleverData.Plan_ID;
      }
      if (cleverData.hasOwnProperty("Plan_Name") === true) {
        data.Plan_Name = cleverData.Plan_Name;
      }
      if (cleverData.hasOwnProperty("Object_ID") === true) {
        data.Object_ID = cleverData.Object_ID;
      }
      if (cleverData.hasOwnProperty("Object_Name") === true) {
        data.Object_Name = cleverData.Object_Name;
      }
      if (cleverData.hasOwnProperty("Object_Quality") === true) {
        data.Object_Quality = cleverData.Object_Quality;
      }

      console.log("charged event from clevertap", data);

      // clevertap.event.push("Charged", data);
    },
    //working
    subscriptionUpdatedCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Status") === true) {
        data.Status = cleverData.Status;
      }
      if (cleverData.hasOwnProperty("Subscription_Start_Date") === true) {
        data.Subscription_Start_Date = cleverData.Subscription_Start_Date;
      }
      if (cleverData.hasOwnProperty("Subscription_End_Date") === true) {
        data.Subscription_End_Date = cleverData.Subscription_End_Date;
      }
      if (cleverData.hasOwnProperty("Subscription_Type") === true) {
        data.Subscription_Type = cleverData.Subscription_Type;
      }
      if (cleverData.hasOwnProperty("Plan_Name") === true) {
        data.Plan_Name = cleverData.Plan_Name;
      }
      if (cleverData.hasOwnProperty("Object_ID") === true) {
        data.Object_ID = cleverData.Object_ID;
      }

      console.log("console from subscription updated clever event", data);

      // clevertap.event.push("Subscription_Updated", data);
    },
    //working
    searchedCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Keyword") === true) {
        data.Keyword = cleverData.Keyword;
      }

      console.log("console from search clever event", data);

      // clevertap.event.push("Searched", data);
    },
    paymentFailedCleverEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Error_Reason") === true) {
        data.Error_Reason = cleverData.Error_Reason;
      }
      if (cleverData.hasOwnProperty("Payment_Mode") === true) {
        data.Payment_Mode = cleverData.Payment_Mode;
      }
      if (cleverData.hasOwnProperty("Amount") === true) {
        data.Amount = cleverData.Amount;
      }
      if (cleverData.hasOwnProperty("Currency_Code") === true) {
        data.Currency_Code = cleverData.Currency_Code;
      }
      if (cleverData.hasOwnProperty("Payment_ID") === true) {
        data.Payment_ID = cleverData.Payment_ID;
      }
      if (cleverData.hasOwnProperty("Transaction_ID") === true) {
        data.Transaction_ID = cleverData.Transaction_ID;
      }
      if (cleverData.hasOwnProperty("Promo_Code") === true) {
        data.Promo_Code = cleverData.Promo_Code;
      }
      if (cleverData.hasOwnProperty("Discount_Amount") === true) {
        data.Discount_Amount = cleverData.Discount_Amount;
      }
      if (cleverData.hasOwnProperty("Free_Trial") === true) {
        data.Free_Trial = cleverData.Free_Trial;
      }
      if (cleverData.hasOwnProperty("Plan_ID") === true) {
        data.Plan_ID = cleverData.Plan_ID;
      }
      if (cleverData.hasOwnProperty("Plan_Name") === true) {
        data.Plan_Name = cleverData.Plan_Name;
      }
      if (cleverData.hasOwnProperty("Object_ID") === true) {
        data.Object_ID = cleverData.Object_ID;
      }
      if (cleverData.hasOwnProperty("Object_Name") === true) {
        data.Object_Name = cleverData.Object_Name;
      }
      if (cleverData.hasOwnProperty("Object_Quality") === true) {
        data.Object_Quality = cleverData.Object_Quality;
      }

      console.log("payment failed clever tap event", data);

      // clevertap.event.push("Payment_Failed", data);
    },
    ratedCleverTapEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Content_ID") === true) {
        data.Content_ID = cleverData.Content_ID;
      }
      if (cleverData.hasOwnProperty("Content_Title") === true) {
        data.Content_Title = cleverData.Content_Title;
      }
      if (cleverData.hasOwnProperty("Content_Type") === true) {
        data.Content_Type = cleverData.Content_Type;
      }
      if (cleverData.hasOwnProperty("Content_Genre") === true) {
        data.Content_Genre = cleverData.Content_Genre;
      }
      if (cleverData.hasOwnProperty("Content_Language") === true) {
        data.Content_Language = cleverData.Content_Language;
      }
      if (cleverData.hasOwnProperty("Content_Duration") === true) {
        data.Content_Duration = cleverData.Content_Duration;
      }
      if (cleverData.hasOwnProperty("Content_Price") === true) {
        data.Content_Price = cleverData.Content_Price;
      }
      if (cleverData.hasOwnProperty("Rating") === true) {
        data.Rating = cleverData.Rating;
      }

      console.log("rating clever tap event", data);

      // clevertap.event.push("Rated", data);
    },
    referralCleverTapEvent(cleverData) {
      if (cleverData === undefined && typeof cleverData !== "object") return;

      let data = {};

      if (cleverData.hasOwnProperty("Medium") === true) {
        data.Medium = cleverData.Medium;
      }

      console.log("Medium clever tap event", data);

      // clevertap.event.push("Referral", data);
    }
  }
};

export default cleverTap;
