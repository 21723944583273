
export const routes = [

  {
		path: "/:lang?/search/:id?",
    name: "SearchPage",
    component: () => import(/* webpackChunkName: 'search page' */ "@/components/Search/SearchPage.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/:lang?/epg",
    name: "Epg",
    component: () => import(/* webpackChunkName: 'epg' */ "@/components/Epg/Epg.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/:lang?/watchlist",
    name: "Watchlist",
    component: () => import(/* webpackChunkName: 'watchlist-page' */ "@/components/watchlist/watchlist.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/:lang?/subscription-plans/:planid?/:coupon?",
    name: "seeAllPlans",
    component: () =>
      import(/* webpackChunkName: "seeAllPlans" */ "@/components/Popups/SeeAllPlans.vue"),
  },
  {
		path: "/:lang?",
    name: "Home",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
    children: [
      {
        path: "/:lang?/plan/:planid",
        name: "plandeeplink",
        component: () => import(/* webpackChunkName: 'plandeeplink' */ "@/components/Popups/planDeeplink.vue"),
      },
      {
        path: "/:lang?/viewContent/:contentId",
        name: "contentdeeplink",
        component: () => import(/* webpackChunkName: "contentdeeplink" */ "@/components/Popups/viewContent.vue"),
      },
      {
        path: "/:lang?/viewTicket/:ticketId",
        name: "ticketdeeplink",
        component: () => import(/* webpackChunkName: "ticketdeeplink" */ "@/components/Popups/viewTicket.vue"),
      },
      {
        path: "/:lang?/viewPlanAndCoupon/:planId/:coupon?",
        name: "planandcoupondeeplink",
        component: () =>
          import(/* webpackChunkName: "planandcoupondeeplink" */ "@/components/Popups/viewPlanAndCoupon.vue"),
      }
    ],
  },
  {
    path: "/:lang?/login",
    // name: "Home",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/tv",
    name: "tvGeneration",
    redirect: "/pairing",
    // component: () => import(/* webpackChunkName: 'tv-generation' */ "@/components/TV/PinGeneration.vue"),
	},
  {
		path: "/pairing",
		name: "loginWithPinView",
		component: () => import(/* webpackChunkName: 'tv-generation' */ "@/components/TV/LoginWithPinView.vue"),
	},

  {
    path: "/:lang?/terms-of-use",
    name: "termsofuse",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/TermsOfUse/termsofuse.vue"),
  },
  {
    path: "/:lang?/about-us",
    name: "about-switch",
    component: () => import(/* webpackChunkName: 'home-about' */ "@/components/AboutUs/About.vue"),
  },
  {
    path: "/:lang?/faq",
    name: "faq-switch",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Popups/Help/FAQ/FaqNewVersion.vue"),
  },
  {
    path: "/:lang?/privacy-policy",
    name: "privacypolicy",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/PrivacyPolicy/privacyPolicy.vue"),
  },

  {
    path: "/:lang?/profile/:listItem",
    name: "Profile",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Profile/Profile.vue"),
  },
  {
    path: "/cast",
    name: "Cast",
    component: () => import(/* webpackChunkName: 'watchlist-page' */ "@/components/CastandCrew/Casting.vue"),
  },
  {
    path: "/emailVerify",
    name: "subscriberVerify",
    component: () =>
      import(/* webpackChunkName: 'subscriberVerfiy-page' */ "@/components/Templates/subscriberVerify.vue"),
  },
  {
    path: "/detailComponent/:category/:title",
    name: "detailComponent",
    component: () => import(/* webpackChunkName: 'detailComponent' */ "@/components/Templates/detailComponent.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/detail/:contentid",
    name: "detail",
    component: () => import(/* webpackChunkName: 'detail' */ "@/components/MobileTemplates/detail.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path:"/:lang?/seeallcontinuewatch",
    name:"seeAllContinueWatch",
    component: () => import(/* webpackChunkName: 'seeAllContinueWatch' */ "@/components/ContinueWatch/continueWatchSeeAll.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path:"/trailerDeeplink/:contentId",
    name:"trailerDeeplink",
    component: () => import(/* webpackChunkName: 'trailerDeeplink' */ "@/components/notificationDeeplinks/trailerDeeplink.vue"),
  },
  {
    path:"/contentPlayDeeplink/:contentId",
    name:"contentPlayDeeplink",
    component: () => import(/* webpackChunkName: 'trailerDeeplink' */ "@/components/notificationDeeplinks/contentPlayDeeplink.vue"),
  },
  {
    path:"/:lang?/switchprofile",
    name:"switchProfile",
    component: () => import(/* webpackChunkName: 'switchProfile' */ "@/components/Profile/SwitchProfile.vue"),
  },
  {
    path:"/:lang?/addProfileCheck",
    name:"addProfileCheck",
    props: {mode: 'addProfile'},
    component: () => import(/* webpackChunkName: 'switchProfile' */ "@/components/Profile/SwitchProfile.vue"),
  },
  {
    path:"/:lang?/addprofile",
    name:"addProfile",
    component: () => import(/* webpackChunkName: 'AddProfile' */ "@/components/Profile/AddProfile.vue"),
  },

  {
    path: "/:lang?/more/:slug/:section",
    name: "collections",
    component: () => import(/* webpackChunkName: 'collections' */ "@/components/collections/collection.vue"),
  },

  {
    path:"/:lang?/:category/:title/:contentId",
    name:"detailPageEarth",
    component: () => import(/* webpackChunkName: 'DetailPage' */ "@/themeearth/components/detail/DetailPage.vue"),
        children: [
      { path: 'season/:id', component: () => import(/* webpackChunkName: 'episodeCard' */ "@/components/DetailPage/episodeCard.vue"),name:"episodes"},
      { path: 'season/:id/episode/:episodeCounter/:episodeId/:isPlayBackActive', component: () => import(/* webpackChunkName: 'episodeCard' */ "@/components/DetailPage/episodeCard.vue"),name:"episodePlayback"},
      { path: 'trailer', component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/trailerCard.vue") , name:"trailer" },
      { path: 'related', component: () => import(/* webpackChunkName: 're' */ "@/components/DetailPage/relatedCard.vue") , name:"related" },
      { path: 'trailer/:trailerId/:isPlayBackActive', component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/trailerCard.vue") , name:"trailerPlaybackSeries" },
    ],
  },

  {
   path: '/movie/:title/:contentId/play', component: () => import(/* webpackChunkName: 'trailerCard' */ "@/components/DetailPage/detailPage.vue") ,
  },
  {
    path: "/:lang?/screen/:section",
    name: "screen",
    component: () => import(/* webpackChunkName: 'screen' */ "@/components/screen/screen.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },

    {
    path: "/:lang?/:section",
    name: "section-screen",
    component: () => import(/* webpackChunkName: 'screen' */ "@/components/screen/sectionWiseScreen.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/lookup",
    // name: "Home",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/help",
    // name: "Home",
    component: () => import(/* webpackChunkName: 'home-help' */ "@/components/Home/Home.vue"),
  },

  {
    path: "/content/details/:id",
    // name: "contentDetails",
    component: () => import(/* webpackChunkName: 'home-page' */ "@/components/Home/Home.vue"),
  },
  {
    path: "/:lang(ml|null)?/:objecttype/:type(genre|category|currentLanguage|productionyear)?/:value",
    name: "screen-meta-redirection",
    component: () => import(/* webpackChunkName: 'screen-meta-tag' */ "@/components/MetaTagRedirection/index.vue")
  
  },
  {
    path: "*",
    name: "ErrorPage",
    component: () => import(/* webpackChunkName: 'ErrorPage' */ "@/components/NotFound/Error404.vue"),
  },

];
