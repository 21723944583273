<template>
  <div id="app">
    <snackbar v-if="isSnackbar" :message="message" :backgroundColor="backgroundColor"></snackbar>
    <!-- <splashScreen></splashScreen> -->
    <Init class="body-min-width"></Init>
    <ScrollToTop />
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { store } from "@/store/store";
import { _fbId } from "./provider-config.js";

import FirebaseActions from "./mixins/FirebaseActions.js";

import firebase from "firebase/app";
import "firebase/auth";

import Utility from "./mixins/Utility";
import {setUserPropertiesFunc} from "@/utilities";

export default {
  mixins: [Utility, FirebaseActions],
  name: "App",
  data() {
    return {
      isSnackbar: null,
      message: "",
      backgroundColor: "",
      mobileWeb: false,
      mobileBrowserController: true,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "newSubscription", "newPurchase", "getDisplayLanguage", "getDisplayLanguageCode", "getCountryListByCode", "getPairingFlag"]),
  },
  watch: {
    subscriberid(val) {
      this.fetchSubscriberList();
      this.fetchPurchaseList();

      if (val) {
        this.firebaseAnonymousLogin();

        this.setUserProfileDetails();
      }
    },
    newSubscription(val) {
      if (val) {
        //fetch subscription list after each subscription.
        this.fetchSubscriberList();
        this.fetchPurchaseList();
      } else if (!val) {
        console.log("subscription cancelled");
      }
    },
    newPurchase(val) {
      if (val) {
        //fetch purchase list after each purchase.
        this.fetchPurchaseList();
      }
    },
    $route(to, from) {
      eventBus.$emit("closeLangPopup")
      this.landingRoutes(to);
      this.setupLandingUrl(to);
      const subscriber = localStorage.getItem("subscriberDetails") ? localStorage.getItem("subscriberDetails") : "";
      if ((to.name === "Watchlist" || to.name === "Bold") && !subscriber) {
        this.$router.push({ name: "Home" });
      }
   
    },
  },
  created() {
  
    // Check if user is offline
    this.checkOffline();

    // Check if user is offline
    this.checkOnline();

    // Check if the User is logged into firebase
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.uid) {
        this.setFirebaseUserUID(user.uid);
      }

      if (this.subscriberid && !user) {
        this.firebaseAnonymousLogin();
      }
    });

    // Detect browser.
    this.checkBrowser();
  },
  mounted() {
    // this.actGetUTMData();
    document.body.style.position = ""


    if(this.subscriberid) {
      this.setUserProfileDetails();
      this.fetchPurchaseList()
    }

    eventBus.$on("re-login-success", async () => {
      await this.fetchSubscriberList();
      await this.fetchPurchaseList();

      // If the Current route is in Details then
      if(this.$route.name === 'detailPageEarth' && this.subscriberid) {
        eventBus.$emit('details-login-success', true);
      }
    });

    // Updating locale based on localstorage value.

    eventBus.$on("check-local-storage", (data) => {
      this.$i18n.locale = data;
    });

    // Event listener for facebook sdk load.
    window.addEventListener("facebook-loaded", this.loadFacebookSdk);

    // if (screen.width < 576) {
    //   window.addEventListener("load", this.removeAddressBar());
    // }

    eventBus.$on("snackBarMessage", (payload) => {
      this.messagesSnackbar(payload);
    });

    // clevertap push notification setup
    // clevertap.notifications.push({
    //   titleText: "Would you like to receive Push Notifications?",
    //   bodyText:
    //     "We promise to only send you relevant content and give you updates on your transactions",
    //   okButtonText: "Sign me up!",
    //   rejectButtonText: "No thanks",
    //   okButtonColor: "#f28046",
    //   askAgainTimeInSeconds: "5"
    // });

    // this.checkScreenSize();

    this.getCountryList().then(response => {
      this.setCountryListByCode(response);
        this.setCountryCodesList(response);
    })

    const subscriber = localStorage.getItem("subscriberDetails") ? localStorage.getItem("subscriberDetails") : "";

    if (!subscriber && location.pathname.includes("/profile")) {
        window.storeAfterLoginPath = location.pathname;
        this.$router.push({ name: "Home" });      

        setTimeout(() => {
          eventBus.$emit("toggle-auth");

        }, 4000)

      }

  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize());
    eventBus.$off("re-login-success");
  },
  methods: {

    ...mapMutations(["setNewSubscription", "setCurrentBrowser", "setRtl", "setFirebaseUserUID", "setProfileid", "setKidsMode", "setCountryListByCode", "setCountryCodesList", "setPairingFlag"]),
    ...mapActions(["listProfiles", "getCountryList"]),
    setupLandingUrl (to) {
      let langInUrl = to.params.lang;
      let defaultLanguage =  localStorage.getItem("setDisplayLanguageCode") ? localStorage.getItem("setDisplayLanguageCode").includes('en') ? 'eng' : localStorage.getItem("setDisplayLanguageCode")  : window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      if(langInUrl === undefined || langInUrl === null){
        localStorage.setItem("setDisplayLanguageCode", defaultLanguage);
         this.$i18n.locale = defaultLanguage;
         return;
      }
      if (to.params.lang) {
        let seletedLanguage = "en";
        if (to.params.lang === "ar") {
          seletedLanguage = "ara";
        }  else if (to.params.lang === "ml") {
          seletedLanguage = "ml";
        }
        localStorage.setItem("setDisplayLanguageCode", seletedLanguage);
         this.$i18n.locale = seletedLanguage == 'en' ? 'eng' : seletedLanguage;
        // eventBus.$emit("check-local-storage", seletedLanguage);
        this.setRtl(seletedLanguage == "en" ? false : true);
      }
    },
    setUserProfileDetails() {
      const localProfileId = localStorage.getItem("currentProfileId");
      const localSubscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (!localProfileId && !localSubscriberProfileDetails) {
        this.listProfiles().then((profileResponse) => {
          if (profileResponse.data.reason) {
            // this.formError = this.$t(profileResponse.data.reason);
          } else {
  
            if (profileResponse.data.totalcount === 1) {
              localStorage.setItem("currentProfileId", profileResponse.data.data[0].profileid);
              localStorage.setItem("subscriberProfileDetails", JSON.stringify(profileResponse.data.data[0]));

              this.setProfileid(profileResponse.data.data[0].profileid);
              let kidsMode = profileResponse.data.data[0].profileid === "NO" ? false : true;
              this.setKidsMode(kidsMode);
            }
          }
        });
      }
    },

    actGetUTMData() {
      let utm_details_global = {};
      let utm_source = this.getParameterByName("utm_source");
      let utm_medium = this.getParameterByName("utm_medium");
      let utm_campaign = this.getParameterByName("utm_campaign");
      if (utm_source) {
        utm_details_global.utm_source = utm_source;
      }
      if (utm_source) {
        utm_details_global.utm_medium = utm_medium;
      }
      if (utm_source) {
        utm_details_global.utm_campaign = utm_campaign;
      }

      localStorage.setItem("utm_details_global", JSON.stringify(utm_details_global));
    },
    landingRoutes(route) {
   //   assigning i18n locale.
      let routename = route.name;
     if (!localStorage.getItem("setDisplayLanguageCode")) {
        const userLang = navigator.language || navigator.userLanguage;
        let seletedLanguage = "eng";

        if (userLang.includes("ar")) {
          seletedLanguage = "ara";
        }
        //setting localstorage value with english.
        localStorage.setItem("setDisplayLanguageCode", seletedLanguage);
        //Initially setting the application locale to english.
        this.$i18n.locale = seletedLanguage;
        eventBus.$emit("check-local-storage", seletedLanguage);
        this.setRtl(seletedLanguage == "eng" ? false : true);
      } else if (localStorage.getItem("setDisplayLanguageCode")) {
        this.$i18n.locale = localStorage.getItem("setDisplayLanguageCode") == 'en' ? 'eng' : localStorage.getItem("setDisplayLanguageCode");
      }

      let langInRoute = route.params.lang;
      let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      let supportedLocales = window.PROVIDER_SETUP_CONFIG.supportLanguageList;
   
      if (location.pathname == '/') {
          let currentLocale = localStorage.getItem("setDisplayLanguageCode");
          if(currentLocale !== defaultLanguage){
            if(currentLocale !== 'en' && defaultLanguage !== 'eng'){
							if(this.getPairingFlag) {

								this.$router.push({ name: "loginWithPinView"});
								}else {
								this.$router.replace({ name: "Home", params: { lang: currentLocale}});
								}
              return;
            }
          }
          if(langInRoute === undefined || langInRoute === null){
            return;
          }
          this.$router.replace({ name: "Home", params: { lang: this.$i18n.locale == 'ml' ? 'ml' : 'en'}});
        }
      if(langInRoute !== undefined && langInRoute !== null) {
        if(!supportedLocales.includes(langInRoute)){
          //render page not found
          this.$router.push({ name: "ErrorPage",});
          return;
        }
      }
    
    },
    checkBrowser() {
      // Get the user-agent string
      let userAgentString = navigator.userAgent;

      //detect chrome
      // let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      // let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
      const userAgent = navigator.userAgent;

      // Define regular expressions for different browsers
      const chromeRegex = /Chrome\/(\S+)/;
      const firefoxRegex = /Firefox\/(\S+)/;
      const safariRegex = /Version\/(\S+).*Safari/;
      const edgeRegex = /Edge\/(\S+)/;
      const ieRegex = /MSIE |Trident\//;
      const braveRegex = /Brave\/(\S+)/; // Regular expression for Brave

      // Check user agent string against regex
      let browserName = "Unknown";
      if (braveRegex.test(userAgent)) {
        browserName = "Brave";
      } else if (chromeRegex.test(userAgent)) {
        browserName = "Chrome";
      } else if (firefoxRegex.test(userAgent)) {
        browserName = "Firefox";
      } else if (safariRegex.test(userAgent)) {
        browserName = "Safari";
      } else if (edgeRegex.test(userAgent)) {
        browserName = "Edge";
      } else if (ieRegex.test(userAgent)) {
        browserName = "Explorer";
      }
      this.setCurrentBrowser(browserName);
      

      // switch (true) {
      //   case chromeAgent:
      //     this.setCurrentBrowser("Chrome");
      //     break;
      //   case firefoxAgent:
      //     this.setCurrentBrowser("Firefox");
      //     break;
      // }
    },
    checkOffline() {
      window.addEventListener("offline", () => {
        this.isSnackbar = true;
        this.message = "You are offline,Please check your connectivity";
        this.backgroundColor = "$clr-offline-snackbar-bg";
        setTimeout(() => {
          this.isSnackbar = false;
        }, 2000);
      });
    },
    checkOnline() {
      window.addEventListener("online", () => {
        this.isSnackbar = true;
        this.message = "Back to Online";
        this.backgroundColor = "$clr-online-snbackbar-bg";
        setTimeout(() => {
          this.isSnackbar = false;
        }, 2000);
      });
    },
    messagesSnackbar(response) {
      this.isSnackbar = response.state;
      this.message = response.message;
      this.backgroundColor = response.color;
      setTimeout(() => {
        this.isSnackbar = false;
      }, 5000);
    },
    async fetchSubscriberList() {
      let payload = {
        subscriptionstatus: "ALL",
      };

      let subscriberDetails = localStorage.getItem("subscriberDetails");

      if (subscriberDetails) {
        subscriberDetails = JSON.parse(subscriberDetails).data;
      } 

      window.selected_Height_width = {};

      store.dispatch("listSubscription", payload).then((response) => {
        if (!response.data.reason) {
          store.commit("setSubscriptionList", response.data.data);
          store.commit("setIsManageCards", true);
          this.setNewSubscription(false);
          this.fetchHighestDate(response.data.data);

          localStorage.setItem("subscriptionDetails", JSON.stringify(response.data.data[0]));
          setUserPropertiesFunc(subscriberDetails, response.data.data[0]);

          let subscriptionDetails = response.data.data[0];
          let selected_quality = subscriptionDetails.quality;
          window.selected_Height_width = {}
          let qualityList = {
            'SD': '854:480',
            'HD': '1920:1080',
            'UHD1': '2560:1440',
            'UHD2': '3840:2160'
          };

          if (qualityList[selected_quality]) {
            window.selected_Height_width.height = qualityList[selected_quality].split(':')[1]
            window.selected_Height_width.width = qualityList[selected_quality].split(':')[0]

            localStorage.setItem("qualitylist", JSON.stringify(window.selected_Height_width))
          }

        }  else {
          localStorage.removeItem("qualitylist");
          window.selected_Height_width = {};

        }
      });
    },
    async fetchPurchaseList() {
      await store
        .dispatch("listPurchases")
        .then((response) => {
          if (!response.data.reason) {
            store.commit("setPurchasesList", response.data.data);
          }
        })
        .catch((error) => console.log(error));
    },
    fetchHighestDate(list) {
      let longExpiry = null;
      let planid = null;

      list.forEach((element) => {
        let date = new Date(element.nextbilling);
        //Initially storing first element to longExpiry.
        if (!longExpiry) {
          longExpiry = date;
          planid = element.planid;
        }
        //checking the highest date.
        if (date > longExpiry) {
          planid = element.planid;
        }
      });
      let index = list.findIndex((el) => {
        return el.planid === planid;
      });
      if (index > -1) {
        let item = list[index];
        // cleverTap profile push updating subscription start and end date.
        // clevertap.profile.push({
        //   Site: {
        //     SubscriptionStartDate: item.planstartdate,
        //     SubscriptionEndDate: item.nextbilling,
        //     SubscriptionStatus: "Paid"
        //   }
        // });
      }
    },
    loadFacebookSdk() {
      window.fbAsyncInit = function() {
        FB.init({
          appId: _fbId,
          autoLogAppEvents: true,
          xfbml: false,
          version: "v7.0",
        });
      };
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    },
    checkScreenSize() {
      window.addEventListener("resize", () => {        
        eventBus.$emit("re-render-carousel", true);
      });
    },
    getParameterByName(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
  },
  components: {
    splashScreen : () => import(/* webpackChunkName: "splashScreen" */ "@/splashScreen.vue"),
    Init: () => import(/* webpackChunkName: "header - init" */ "@/components/Init/Init.vue"),
    snackbar: () => import("@/components/snackbar/Snackbar.vue"),
    ScrollToTop: () => import("@/components/utils/BackToTop.vue"),
  },

};
</script>

<style lang="scss">
  @import "./sass/variables";
  @import "./App.scss";
 
  .snackbar-container  .snackbar-pos .bottom-center {
    p {
      --o-snack-top: calc(100vh - 150px);
      top: var(--o-snack-top) !important;
    }
  }
</style>
