// import Vue from "vue";
import {
	errorServiceHandler,
	makeQueryStringDync,
	toFormUrlEncoded,
	errorHandlerMiddleware,
	setHeader,
} from "../../helper";

// login API call
const actions = {
	//lookup
	lookUp: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = makeQueryStringDync(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/verifyuser${data}`, {
				method: "GET",
				headers: setHeader("json", "deviceToken"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.lookUp(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => reject(error));
		});
	},
	actDeckingCallService: (context, payload) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vCmsBaseUrl}subscriber/v1/deckingconfig`, {
				method: "GET",
				headers: setHeader("json", "deviceToken"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.lookUp(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => reject(error));
		});
	},
	//subscriber - signup//
	signup: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			console.log(context.getters.listextrapaths, "=====")
			fetch(`${context.getters.vSmsBaseUrl}${context.getters.listextrapaths.subscriber}`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
				// }
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.signup(context, payload)))

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//subscriber - login//
	login: (context, payload) => {

		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			// let data = makeQueryStringDync(payload);
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/login?${data}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
				},

				// setHeader(),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//resendlink to user
	resendLink: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}${context.getters.listextrapaths.resendsms}`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.resendLink(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => reject(error));
		});
	},

	//subscriber - logout//
	logout: (context) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/logout`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.logout(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//get Subscriber Info API call - config endpoint
	subscriberDetails: (context) => {
		return new Promise((resolve, reject) => {
			fetch(
				`${context.getters.vSmsBaseUrl}${context.getters.appConfig.apiUtilities.subscriberDetails
					? context.getters.appConfig.apiUtilities.subscriberDetails
					: "subscriber/v1"
				}/subscriber`,
				{
					method: "GET",
					headers: {
						"X-SESSION": `${localStorage.getItem("sessionToken")}`,
					},
				}
			)
				.then((response) => errorHandlerMiddleware(response, () => actions.subscriberDetails(context)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	subscriberPlaybackExpiry: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			// let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/rentalupdate`, {
				method: "POST",
				body: JSON.stringify(payload),
				headers: setHeader("json"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.subscriberEmailConfirm(context, payload)))

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//subscriberEmailConfirm
	subscriberEmailConfirm: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscriber/emailconfirm`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.subscriberEmailConfirm(context, payload)))

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//newEmail flow
	newEmailconfirm: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			
			fetch(`${context.getters.vSmsBaseUrl}${context.getters.listextrapaths.confirm}`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.subscriberEmailConfirm(context, payload)))

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	subscriberVerify: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/verifyuser`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.subscriberVerify(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//forgot password request//
	forgotPasswordRequest: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/forgotpassword`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.forgotPasswordRequest(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	//forgot password confirmation//
	forgotPasswordConfirmation: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/forgotpassword`, {
				method: "PUT",
				body: JSON.stringify(payload),
				headers: setHeader("json"),

				// {
				//   "Content-Type": "application/json",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then((response) =>
					errorHandlerMiddleware(response, () => actions.forgotPasswordConfirmation(context, payload))
				)

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	//
	parentalControlStatus: (context, payload) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/parentalcontrol`, {
				method: "GET",
				headers: setHeader("json"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	parentalControlPinSet: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = makeQueryStringDync(payload);
			//let data = toFormUrlEncoded(payload);
			// https://vsms.mobiotics.com/prodv3/subscriberv2/v1/subscriber/otp?otp_
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscriber/otp${data}`, {
				method: "GET",
				headers: setHeader("json"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	deleteAccount:(context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = makeQueryStringDync(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscriber/otp${data}`, {
				method: "GET",
				headers: setHeader("json"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	deleteUserApi: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscriber/delete`, {
				method: "DELETE",
				body: data,
				headers: setHeader("formUrl"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	parentalControlPinVerify: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/parentalcontrol`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	parentalControlVerifyOldPin: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/parentalcontrol/verify`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	parentalControlResetPin: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/parentalcontrol/reset`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	sendProfilePinOTP: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/profile/resetpin`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	resetProfilePin: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);

			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/profile/confirmresetpin`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	submitfeedback: (context, payload) => {
		
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);

			fetch(`${context.getters.vCrmBaseUrl}subscriber/v1/feedback`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.login(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	parentalControlPgRating: (context, payload) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/parentalcontrol/${payload.profileid}`, {
				method: "PUT",
				body: JSON.stringify(payload),
				headers: setHeader("json"),
			})
				.then((response) =>
					errorHandlerMiddleware(response, () => actions.forgotPasswordConfirmation(context, payload))
				)

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	parentalControlEnableDisable: (context, payload) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/parentalcontrol`, {
				method: "PUT",
				body: JSON.stringify(payload),
				headers: setHeader("json"),
			})
				.then((response) =>
					errorHandlerMiddleware(response, () => actions.forgotPasswordConfirmation(context, payload))
				)

				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default {
	actions,
};
